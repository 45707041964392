

































































































































































































import Base from '@/mixins/Base.vue';

import { IInfringement, IInfringementResponse } from '@/interfaces/infringement';

const component = Base.extend({

    data() {
        return {
            isLoading: false,
            activeTab: 'general',
            isAddCodeModalActive: false,
            infringement: undefined as (any | IInfringement),

        };
    },
    mounted() {
        this.getInfringement();
    },
    methods: {

        getInfringement(): void {
            this.get<IInfringementResponse>(`infringements/${this.$route.params.id}`).then(({ data }) => {
                this.infringement = data.data;
            });
        },
        saveInfringement(): void {
            this.put<IInfringementResponse>(`infringements/${this.$route.params.id}`, {
                ...this.infringement,
            }).then(({ data }) => {
                this.infringement = data.data;
                this.$emit('success', { message: 'infringement updated' });
            });
        },

    },
});

export default component;
