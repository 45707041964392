var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"level"},[_c('div',{staticClass:"level-left"},[_c('h1',{staticClass:"level-item"},[_c('sp-link',{staticClass:"mr-3",attrs:{"tag":"b-button","to":{name:'infringements.list'},"icon-left":"arrow-left"}}),( ! _vm.infringement)?_c('b-skeleton',{attrs:{"size":"is-medium","animated":"","width":"300"}}):[_c('h1',{staticClass:"title"},[_vm._v(" Edit "+_vm._s(_vm.infringement.name)+" "),(_vm.infringement.client)?_c('sp-link',{attrs:{"to":{name:'infringements.edit',params:{id: _vm.infringement.client.id}}}},[_c('b-tag',{staticClass:"ml-3"},[_vm._v(" "+_vm._s(_vm.infringement.client.name)+" ")])],1):_vm._e()],1)]],2)]),_c('div',{staticClass:"level-right"},[_c('b-button',{staticClass:"level-item",class:{'is-loading': _vm.isLoading},attrs:{"disabled":_vm.isLoading,"type":"is-primary","icon-left":"save"},on:{"click":function($event){return _vm.saveInfringement()}}},[_vm._v(" "+_vm._s(_vm.$t('save'))+" ")])],1)]),(_vm.infringement)?_c('b-tabs',{staticClass:"block",attrs:{"position":"is-centered","destroy-on-hide":"","expanded":""},on:{"input":function($event){_vm.activeTab = $event}}},[_c('b-tab-item',{attrs:{"label":"General","value":"general","icon":"info-circle"}},[_c('div',{staticClass:"block"},[_c('div',{staticClass:"subtitle"},[_vm._v(" General ")]),_c('ValidationProvider',{attrs:{"immediate":"","rules":"required","name":"title","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"horizontal":"","label":"Title","type":{'is-danger': errors[0]},"message":errors ? errors[0] : null}},[_c('b-input',{attrs:{"disabled":_vm.isLoading,"expanded":""},model:{value:(_vm.infringement.title),callback:function ($$v) {_vm.$set(_vm.infringement, "title", $$v)},expression:"infringement.title"}})],1)]}}],null,false,3283895787)}),_c('ValidationProvider',{attrs:{"immediate":"","rules":"required","name":"priority","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"horizontal":"","label":"Priority","type":{'is-danger': errors[0]},"message":errors ? errors[0] : null}},[_c('b-select',{attrs:{"disabled":_vm.isLoading,"expanded":""},model:{value:(_vm.infringement.priority),callback:function ($$v) {_vm.$set(_vm.infringement, "priority", $$v)},expression:"infringement.priority"}},[_c('option',{attrs:{"value":"low"}},[_vm._v(" Low ")]),_c('option',{attrs:{"value":"medium"}},[_vm._v(" Medium ")]),_c('option',{attrs:{"value":"high"}},[_vm._v(" Hight ")])])],1)]}}],null,false,771330288)}),_c('ValidationProvider',{attrs:{"immediate":"","rules":"required","name":"status","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"horizontal":"","label":"Status","type":{'is-danger': errors[0]},"message":errors ? errors[0] : null}},[_c('b-select',{attrs:{"disabled":_vm.isLoading,"expanded":""},model:{value:(_vm.infringement.status),callback:function ($$v) {_vm.$set(_vm.infringement, "status", $$v)},expression:"infringement.status"}},[_c('option',{attrs:{"value":"done"}},[_vm._v(" Done ")]),_c('option',{attrs:{"value":"pending"}},[_vm._v(" Pending ")])])],1)]}}],null,false,3015875411)}),_c('ValidationProvider',{attrs:{"immediate":"","rules":"required","name":"description","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"horizontal":"","label":"Description","type":{'is-danger': errors[0]},"message":errors ? errors[0] : null}},[_c('b-input',{attrs:{"disabled":_vm.isLoading,"type":"textarea","expanded":""},model:{value:(_vm.infringement.description),callback:function ($$v) {_vm.$set(_vm.infringement, "description", $$v)},expression:"infringement.description"}})],1)]}}],null,false,334980143)})],1),(_vm.infringement.object)?_c('sp-has-permission',{staticClass:"block",attrs:{"name":"objects.read"}},[_c('div',{staticClass:"subtitle"},[_vm._v(" Object ")]),_c('sp-link',{attrs:{"to":{name:'objects.edit',params:{id: _vm.infringement.object.id}}}},[_vm._v(" "+_vm._s(_vm.infringement.object.name)+" ")])],1):_vm._e(),(_vm.infringement.job)?_c('sp-has-permission',{staticClass:"block",attrs:{"name":"jobs.read"}},[_c('div',{staticClass:"subtitle"},[_vm._v(" Job ")]),_c('sp-link',{attrs:{"to":{name:'jobs.edit',params:{id: _vm.infringement.job.id}}}},[_vm._v(" "+_vm._s(_vm.infringement.job.code)+" ")])],1):_vm._e()],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }